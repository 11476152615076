import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import Header from './components/Header'

export const HelpDesk = () => {
    const navigate = useNavigate();
    const [{ data: users, ...data }, setUsers] = useState<any>({})


    return (
        <>
            <div className='row'>
                <div className="col-12">
                    <div className="card card-background card-background-after-none align-items-start mt-4 mb-5">
                        <div className="full-background" style={{ backgroundImage: "url('../assets/img/header-blue-purple.jpg')" }}></div>
                        <div className="card-body text-start p-4 w-100">
                            <h3 className="text-white mb-2">HelpDesk 🔥</h3>
                            <p className="mb-4 font-weight-semibold">
                                Check all the advantages and choose the best.
                            </p>
                            <button type="button" className="btn btn-outline-white btn-blur btn-icon d-flex align-items-center mb-0">
                                <span className="btn-inner--icon">
                                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="d-block me-2">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6.61036 4.52196C6.34186 4.34296 5.99664 4.32627 5.71212 4.47854C5.42761 4.63081 5.25 4.92731 5.25 5.25V8.75C5.25 9.0727 5.42761 9.36924 5.71212 9.52149C5.99664 9.67374 6.34186 9.65703 6.61036 9.47809L9.23536 7.72809C9.47879 7.56577 9.625 7.2926 9.625 7C9.625 6.70744 9.47879 6.43424 9.23536 6.27196L6.61036 4.52196Z" />
                                    </svg>
                                </span>
                                <span className="btn-inner--text">Watch more</span>
                            </button>
                            <img src="/assets/img/3d-cube.png" alt="3d-cube" className="position-absolute top-0 end-1 w-25 max-width-200 mt-n6 d-sm-block d-none" />
                        </div>
                    </div>
                </div>
            </div >
            <Header/>
            <div className="row">
                <div className="col-12">
                    <div className="card border shadow-xs mb-4">
                        
                        <div className="card-body px-0 py-0">
                            <div className="border-bottom py-3 px-3 d-sm-flex align-items-center">
                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="btnradiotable" id="btnradiotable1" autoComplete="off" checked />
                                    <label className="btn btn-white px-3 mb-0" htmlFor="btnradiotable1">Todos</label>
                                    <input type="radio" className="btn-check" name="btnradiotable" id="btnradiotable2" autoComplete="off" />
                                    <label className="btn btn-white px-3 mb-0" htmlFor="btnradiotable2">Membros</label>
                                    <input type="radio" className="btn-check" name="btnradiotable" id="btnradiotable3" autoComplete="off" />
                                    <label className="btn btn-white px-3 mb-0" htmlFor="btnradiotable3">Gestores</label>
                                </div>
                                <div className="input-group w-sm-25 ms-auto">
                                    <span className="input-group-text text-body">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                                        </svg>
                                    </span>
                                    <input type="text" className="form-control" placeholder="Pesquiser" />
                                </div>
                            </div>
                            <div className="table-responsive p-0">

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-3 col-sm-6 mb-xl-0" onClick={()=>navigate("/admin/help-desk/chat")}>
                    <div className="card border shadow-xs mb-4">
                        <div className="card-body text-start p-3 w-100">
                        <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                            <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                            <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                            <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clip-rule="evenodd"></path>
                            </svg>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <div className="w-100">
                                <p className="text-sm text-secondary mb-1">Programs</p>
                                <h4 className="mb-2 font-weight-bold">Chat</h4>
                                <div className="d-flex align-items-center">
                                <span className="text-sm ms-1">Use este chat para atender, esclarecer dúvidas e solucionar problemas dos clientes de forma eficiente e profissional.</span>
                                </div>
                            </div>
                                </div>
                                
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
