import React from 'react'

const Header = () => {
  return (
    <div>
      <div className="row flex-column-center">
      <div className="col-sm-10 col-md-6">
        <div className="info text-align-left">

                      <h1 className="font-extrabold mt-20 mb-20 letter-tight text-gray-800" style={{fontWeight:'bolder'}}>
                          Assistência Técnica e Suporte Especializado Sempre ao Seu Lado</h1>
          <p className="text-gray-500 text-lg">
              Estamos aqui para ajudá-lo com suas dúvidas e problemas técnicos, garantindo soluções rápidas, eficientes e personalizadas para você.
              
          </p>

        </div>
      </div>
      <div className="col-sm-12 col-md-6 perspective">
        <div className="circle-wrapper d-none">
          <div className="circle circle-1"></div>
          <div className="circle circle-2"></div>
          <div className="circle circle-3"></div>
          <div className="icons">
            
          </div>
        </div>

        <div className="skew">
          <div className="row skew-container">
            <div className="col-md-6">
              
            </div>

            <div className="col-md-6" >
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Header
