import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { apiRequest } from '../../../../../../utils/api';
import { ProgressLoading, PulseLoading } from '../../../../../Common/components/Loading';
import { loadavg } from 'os';

const BioData = ({ user, refresh }: any) => {
    const { userData } = user;
    const [editing, setEditing] = useState<boolean>(false)
    return (
        <div>
            <Row>
                <Col md={8}>
                    <h3 className="display-6"> Bio </h3>
                </Col>
                <Col md={4}>
                    {editing
                        ? <Button style={{ float: 'right' }} size='sm' variant='white' onClick={() => setEditing(false)}>X</Button>
                        : <Button style={{ float: 'right' }} size='sm' variant='white' onClick={() => setEditing(true)}>Editar</Button>
                    }


                </Col>
            </Row>
            {
                editing
                    ? <BioDataForm userData={userData} refresh={refresh} setEditing={setEditing} />
                    : <BioDataView userData={userData} />
            }

            <hr />
        </div>
    )
}
const BioDataView = ({ userData }: any) => {

    return (<>


        <p>Bio  {userData?.firstName} {userData?.lastName}  </p>
        <h5>{userData?.bio ?? '-'}</h5>
    </>)
}



interface BioFormInputs {
    bio: string;
}
export const BioDataForm = ({ userData, setEditing, refresh }: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setErrors] = useState<any>({});
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const { register, handleSubmit, reset, formState: { errors } } = useForm<BioFormInputs>({
        defaultValues: { ...userData }
    });

    const onSubmit: SubmitHandler<BioFormInputs> = (data: any) => {
        const { bio } = data;

        formSubmit({
            bio,
        } as any);

    }

    useEffect(() => {
        if (savedResponse !== undefined) {
            reset();
            refresh()
            setEditing(false)
        }
    }, [savedResponse])

    const formSubmit = (body: BioFormInputs) => {
        setLoading(true);
        apiRequest({
            endpoint: '/users-data/' + userData?.id,
            method: 'put',
            body
        }).then((data) => {
            setSavedResponse(data)
        }).catch(({ data }) => setErrors(data))
            .finally(() => setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px', padding: 15 }}>

            {error?.error &&
                <div className="alert alert-danger text-dark text-sm" role="alert">
                    <strong>Erro: </strong> {error?.error?.message}
                </div>
            }
            <Row>



                <Col md={12}>
                    <label htmlFor="title">Sobre  {userData?.firstName} {userData?.lastName} </label>
                    <textarea

                        className={"form-control " + (errors.bio && "is-invalid")}
                        maxLength={256}
                        data-name="Name"
                        placeholder="Instagram"


                        {...register("bio")}
                    >
                    </textarea>
                    <span className='error'>{errors.bio && <p>{errors.bio.message}</p>}</span>
                </Col>


            </Row>
            {loading && <ProgressLoading />}
            <hr />
            <button type="submit" className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2" disabled={loading}>
                {loading ? <PulseLoading /> : <span className="btn-inner--icon">

                </span>}
                <span className="btn-inner--text">Registar novo</span>

            </button>

        </form>
    );
}
export default BioData
