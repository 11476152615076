import moment from 'moment';
import React from 'react'
interface ChatBumblePropsType {
  type: "replies" | "sent";
  message: any
}

function convertLinksToHtml(text: any) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, (url: any) => {

    if (/\.(jpg|jpeg|png|gif|bmp|webp|svg)$/i.test(url)) {
      return `<img src="${url}" alt="Image" style="max-width: 100%; " />`;
    }

    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
}

const parseText = (inputText: string): string => {
  let final = convertLinksToHtml(inputText);

  return final;
}

const ChatBumble = ({ type, message }: ChatBumblePropsType) => {
  const htmlString = parseText(message.content ?? '')
  return (
    <li className={message?.type === 'LOG' ? message?.type?.toLowerCase() : type} title={message?.member?.user?.email}>
      <div className='box'>
        {message?.type !== 'LOG' && <img src={"https://ui-avatars.com/api/?name=" + message?.member?.user?.email} alt="" />}
        <p>
          <small><b>{message?.member?.user?.userData?.firstName ? message?.member?.user?.userData?.firstName + ' ' + message?.member?.user?.userData?.lastName : message?.member?.user?.email}</b></small><br />

          <div dangerouslySetInnerHTML={{ __html: htmlString }} />
          <hr />
          <small>{moment(message?.createdAt).fromNow()}</small>
        </p>
      </div>
    </li>
  )
}

export default ChatBumble
