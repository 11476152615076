import React, { useEffect, useState } from 'react'
import { apiRequest } from '../../../../../../../utils/api';
import MentorCreate from './MentorCreate';
import moment from 'moment';
import { env } from 'process';
import MentorDelete from './MentorDelete';
import { ProgressLoading } from '../../../../../../Common/components/Loading';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MentorList = ({ program }: any) => {


  const [{ data: mentors, ...data }, setMentors] = useState<any>({ data: [] })
  const [selectedMentor, setMentor] = useState<any>({})

  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState<number>();
  const [showProgramCreateModal, setShowProgramCreateModal] = useState(false);
  useEffect(() => {
    if (program?.id) {
      setLoading(true)
      apiRequest({
        endpoint: '/program-mentors',
        method: 'get',
        urlQueryParams: { page: currentPage, limit: 6, programId: program?.id }
      }).then((data) => {
        setMentors(data)
      }).catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }, [program, currentPage, refresh])

  const handleEditMentor = (mentor: any) => {
    setMentor(mentor);
    setShowProgramCreateModal(true)
  }
  return (
    <div className="table-responsive p-0">
      <MentorCreate refresh={() => setRefresh(Math.random())} show={showProgramCreateModal} program={program} setShow={setShowProgramCreateModal} />
      <table className="table align-items-center justify-content-center mb-0">
        <thead className="bg-gray-100">
          <tr>
            <th className="text-secondary text-xs font-weight-semibold opacity-7">Mentor</th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">E-mail</th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">Tipo</th>
            <th className="text-secondary text-xs font-weight-semibold opacity-7 ps-2">Data de registo</th>
            <th className="text-right text-secondary text-xs font-weight-semibold opacity-7">
              <Button size="sm" variant="outline-dark" onClick={() => handleEditMentor(undefined)} >
                <svg fill="#000000" width="14" height="14" viewBox="-4.5 -4.5 24 24" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin" className="jam jam-plus"><path d='M8.9 6.9v-5a1 1 0 1 0-2 0v5h-5a1 1 0 1 0 0 2h5v5a1 1 0 1 0 2 0v-5h5a1 1 0 1 0 0-2h-5z' /></svg>
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {mentors?.map((mentor: any) =>
            <tr>
              <td>
                <div className="d-flex px-2">
                  <div className="avatar avatar-sm rounded-circle bg-gray-100 me-2 my-2">
                    <img src={mentor?.user?.userData?.avatar ?? "/assets/img/unknow.png"} className="w-80" alt="spotify" />
                  </div>
                  <div className="my-auto">
                    <Link to={"/admin/users/" + mentor?.user?.id}>
                      <h6 className="mb-0 text-sm">{mentor?.user?.userData?.firstName} {mentor?.userData?.lastName}</h6></Link>
                  </div>
                </div>
              </td>
              <td>
                <span className="text-sm font-weight-normal">{mentor?.user?.email}</span>
              </td>
              <td>
                <p className="text-sm font-weight-normal mb-0">{mentor?.user?.role}</p>
              </td>
              <td className="align-middle">

                <span className="text-sm font-weight-normal">{moment(mentor?.createdAt).format('DD/MM/YYYY')}</span>
              </td>
              <td className="align-end align-right text-right ">


                <MentorDelete mentor={mentor} refresh={() => setRefresh(Math.random())} >
                  <Button variant="outline-dark" size="sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
                    </svg>
                  </Button>
                </MentorDelete>
              </td>
            </tr>)}

        </tbody>
      </table>
      {loading && <ProgressLoading />}
    </div >
  )
}

export default MentorList
