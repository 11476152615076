import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { SubmitHandler, useForm } from 'react-hook-form'
import { apiRequest } from '../../../../../../utils/api'
import moment from 'moment'
import { ProgressLoading, PulseLoading } from '../../../../../Common/components/Loading'

const MainData = ({ user, refresh }: any) => {
    const { userData } = user;
    const [editing, setEditing] = useState<boolean>(false)
    return (
        <div>
            <Row>
                <Col md={8}>
                    <h3 className="display-6">Personal data </h3>
                </Col>
                <Col md={4}>
                    {editing
                        ? <Button style={{ float: 'right' }} size='sm' variant='white' onClick={() => setEditing(false)}>X</Button>
                        : <Button style={{ float: 'right' }} size='sm' variant='white' onClick={() => setEditing(true)}>Editar</Button>
                    }


                </Col>
            </Row>
            {
                editing
                    ? <MainDataForm userData={userData} refresh={refresh} setEditing={setEditing} />
                    : <MainDataView userData={userData} />
            }

            <hr />
        </div>
    )
}
const MainDataView = ({ userData }: any) => {
    return (
        <div>
            <p>Nome</p>
            <h5>{userData?.firstName ?? '-'} {userData?.lastName ?? '-'}</h5>

            <p>Genero</p>
            <h5>{userData?.gender ?? '-'}</h5>

            <p>Data de Nascimento</p>
            <h5>{userData?.birthdate ? moment(userData?.birthdate).format('DD/MM/YYYY') : '-'}</h5>

            <p>estado Laboral</p>
            <h5>{userData?.laboralState ?? '-'}</h5>

        </div>
    )
}



interface PropertyFormInputs {
    birthdate: Date;
    firstName: string;
    lastName: string;
    laboralState: string;
    gender: string;
    telephone: string;
    email: string;
}
export const MainDataForm = ({ userData, setEditing, refresh }: any) => {

    const [error, setErrors] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const { register, handleSubmit, reset, formState: { errors } } = useForm<PropertyFormInputs>({
        defaultValues: { ...userData, birthdate: moment(userData.birthdate).format('YYYY-MM-DD') }
    });

    const onSubmit: SubmitHandler<PropertyFormInputs> = (data: any) => {
        const { birthdate,
            firstName,
            lastName,
            laboralState,
            gender,
            telephone,
            email } = data;

        formSubmit({
            birthdate,
            firstName,
            lastName,
            laboralState,
            gender,
            telephone,
            email
        });

    }

    useEffect(() => {
        if (savedResponse !== undefined) {
            reset();
            refresh()
            setEditing(false)
        }
    }, [savedResponse])

    const formSubmit = (body: PropertyFormInputs) => {
        setLoading(true)
        apiRequest({
            endpoint: '/users-data/' + userData?.id,
            method: 'put',
            body
        }).then((data) => {
            setSavedResponse(data)
        }).catch(({ data }) => setErrors(data))
            .finally(() => setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px', padding: 15 }}>

            {error?.error &&
                <div className="alert alert-danger text-dark text-sm" role="alert">
                    <strong>Erro: </strong> {error?.error?.message}
                </div>
            }
            <Row>
                <Col md={12}>
                    <label htmlFor="title">Nome completo</label>
                    <input
                        className={"form-control " + (errors.firstName && "is-invalid")}
                        maxLength={256}
                        data-name="Name"
                        placeholder="Nome completo"
                        type="text"

                        {...register("firstName", { required: "firstName is required" })}
                    >
                    </input>
                    <span className='error'>{errors.firstName && <p>{errors.firstName.message}</p>}</span>
                </Col>


                <Col md={12}>
                    <label htmlFor="title">Ultimo Nome</label>
                    <input
                        className={"form-control " + (errors.lastName && "is-invalid")}
                        maxLength={256}
                        data-name="Name"
                        placeholder="Nome completo"
                        type="text"

                        {...register("lastName", { required: "Name is required" })}
                    >
                    </input>
                    <span className='error'>{errors.lastName && <p>{errors.lastName.message}</p>}</span>
                </Col>


                <Col md={12}>
                    <label htmlFor="title">Data de nascimento</label>
                    <input

                        className={"form-control " + (errors.birthdate && "is-invalid")}
                        maxLength={256}
                        data-name="Name"
                        placeholder="Nome completo"
                        type="date"

                        {...register("birthdate", { required: "Name is required" })}
                    >
                    </input>
                    <span className='error'>{errors.birthdate && <p>{errors.birthdate.message}</p>}</span>
                </Col>


                <Col md={12}>
                    <label htmlFor="title">Gênero</label>
                    <select
                        className={"form-control " + (errors.gender && "is-invalid")}

                        data-name="Name"

                        {...register("gender", { required: "role is required" })}
                    >
                        <option>Masculino</option>
                        <option>Feminino</option>
                    </select>
                    <span className="error">{errors.gender && <p>{errors.gender.message}</p>}</span>
                </Col>
                <Col md={12} s>
                    <label htmlFor="title">Estado Laboral</label>
                    <select
                        className={"form-control " + (errors.laboralState && "is-invalid")}

                        data-name="Name"

                        {...register("laboralState", { required: "laboralState is required" })}
                    >
                        {[
                            ["WORKER", "Trabalhador"],
                            ["WORKER_STUDENT", "Trabalhador-Estudante"],
                            ["STUDENT", "Estudante"]
                        ].map(([a, b]: any) => <option value={b}>{b}</option>)}

                    </select>
                    <span className="error">{errors.laboralState && <p>{errors.laboralState.message}</p>}</span>
                </Col>
            </Row>

            {loading && <ProgressLoading />}
            <hr />
            <button type="submit" className="btn btn-sm btn-dark btn-icon d-flex align-items-center me-2" disabled={loading}>
                {loading ? <PulseLoading /> : <span className="btn-inner--icon">

                </span>}
                <span className="btn-inner--text">Registar novo</span>

            </button>

        </form>
    );
}

export default MainData
