import React, { useState } from 'react'
import { PulseLoading } from '../../../../../../Common/components/Loading'
import { RegulationModal } from '../Regulation/RegulationModal'

export const ProgramEnrolling = ({ enrolled, enroll, loading }: any) => {
    const [show, setShow] = useState(false)
    return (
        <>
            {enrolled?.id ?
                <button onClick={enroll} className="btn btn-sm btn-success btn-icon d-flex align-items-center mb-0 me-2" type="button">
                    {loading && <PulseLoading />} {" "}  Já Inscrito
                </button> :


                <button onClick={() => setShow(true)} className="btn btn-sm btn-primary btn-icon d-flex align-items-center mb-0 me-2" type="button">
                    {loading && <PulseLoading />} {" "}  Inscrever-se agora
                </button>}
            <RegulationModal show={show} setShow={setShow} onAccept={enroll} />
        </>
    )
}
