import React, { useEffect, useState } from 'react'
import * as _ from 'lodash';
import { apiRequest } from '../../../../utils/api'
import { ProgressLoading } from '../../../Common/components/Loading'

const ChatList = ({ user, selectChat, selectedChat }: any) => {

    const [{ data: myChats }, setMyChats] = useState<any>({ data: [] })
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {

        const filter = user?.role === 'ADMIN' ? {} : { userId: user?.id }
        if (user?.id) {
            setLoading(true);
            apiRequest({
                endpoint: '/common/chat-memberships',
                method: 'get',
                urlQueryParams: { page: 1, limit: 100, ...filter }
            }).then(setMyChats)
                .finally(() => setLoading(false))
        }
    }, [user])

    return (

        <div id="contacts">
            {loading && <ProgressLoading />}
            <ul>
                {_.uniqBy(myChats, 'chat.id')?.map(({ chat }: any) => <li className={"contact " + (chat?.id === selectedChat?.id && "selected")}>
                    <div className="wrap" onClick={() => selectChat(chat)}>
                        <span className="contact-status online"></span>
                        <img src={`https://ui-avatars.com/api/?name=${chat.title.split(' ').join('+')}`} alt="" />
                        <div className="meta">
                            <p className="name">{chat?.title}</p>
                            <p className="preview">{chat?.memberships?.find(({ userId }: any) => userId !== user?.id)?.user?.email}</p>
                        </div>
                    </div>
                </li>)}

            </ul>
        </div>
    )
}

export default ChatList
