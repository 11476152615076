import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { apiRequest } from '../../../../../../../utils/api';
import { ProgressLoading, PulseLoading } from '../../../../../../Common/components/Loading';
import { TextEditor } from '../../../ProgramCreate/TextEditor';

const EventCreate = ({ show, setShow, refresh, event = undefined }: any) => {


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{event?.id ? "Actualizar" : "Registar novo"}  Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EventCreateForm event={event} closeModal={handleClose} refresh={refresh} />
            </Modal.Body>
        </Modal>

    )
}

interface EventType {
    title: string,
    description: string,
    startEventDate: string,
    endEventDate: string,
    programId?: string,
    isActive?: boolean,
}

const EventCreateForm = ({ refresh, closeModal, event }: any) => {

    const [error, setErrors] = useState<any>({});
    const [savedResponse, setSavedResponse] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const { register, handleSubmit, reset,control, formState: { errors } } = useForm<EventType>({
        defaultValues: {
            ...event,
            programId: event?.program?.id,
            startEventDate: event?.startEventDate && moment(event?.startEventDate).format('YYYY-MM-DD'),
            endEventDate: event?.endEventDate && moment(event?.endEventDate).format('YYYY-MM-DD')
        }
    });

    const onSubmit: SubmitHandler<EventType> = (data: any) =>
        formSubmit(data);

    useEffect(() => {

        if (savedResponse !== undefined) {
            reset();
            refresh();
            closeModal()
        }
    }, [savedResponse])

    const formSubmit = (body: EventType) => {
        setLoading(true)
        apiRequest({
            endpoint: '/events' + (event?.id ? "/" + event?.id : ""),
            method: event?.id ? 'put' : 'post',
            body
        }).then((data) => {
            setSavedResponse(data)
        }).catch(({ data }) => setErrors(data))
            .finally(() => setLoading(false))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '400px', margin: '0 auto', padding: 15 }}>

            {error?.error && <div className='alert alert-danger'>{error?.error?.message}</div>}
            <Row>
                <Col xs={12}>
                    <label htmlFor="title">Titulo</label>
                    <input
                        className="form-control ps-0"
                        maxLength={256}
                        data-name="title"
                        placeholder="Titulo"
                        type="text"

                        {...register("title", { required: "Email is required" })}
                    >
                    </input>
                    <span className='error'>{errors.title && <p>{errors.title.message}</p>}</span>
                </Col>

                <Col xs={12}>
                    <label htmlFor="title">Descricao</label>
                  
                    <TextEditor
                        {...register("description", { required: "Email is required" })} control={control} />
                    <span className='error'>{errors.description && <p>{errors.description.message}</p>}</span>
                </Col>

                <Col md={6}>
                    <label htmlFor="title">Data de Inicio</label>
                    <input
                        className="form-control ps-0"

                        data-name="title"
                        placeholder="Data de inicio"

                        type="date"

                        {...register("startEventDate", { required: "Date is required" })}
                    >
                    </input>
                    <span className='error'>{errors.startEventDate && <p>{errors.startEventDate.message}</p>}</span>
                </Col>

                <Col md={6}>
                    <label htmlFor="title">Data de Fim</label>
                    <input
                        className="form-control ps-0"
                        maxLength={256}
                        data-name="title"
                        placeholder="Titulo"
                        type="date"

                        {...register("endEventDate", { required: "Date is required" })}
                    >
                    </input>
                    <span className='error'>{errors.endEventDate && <p>{errors.endEventDate.message}</p>}</span>
                </Col>

                <Col>

                    <hr />
                    <button type="submit" className="btn btn-dark btn-icon d-flex align-items-center me-2" disabled={loading}>
                        <span className="btn-inner--icon">

                        </span>
                        <span className="btn-inner--text">Salvar</span>
                        {loading && <PulseLoading />}
                    </button>
                </Col>
            </Row>
            {loading && <ProgressLoading />}
        </form>
    );
}

export default EventCreate
