import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard'
import Properties from './Properties'
import { PropertiesDetail } from './Properties/PropertiesDetail/PropertiesDetail'
import { TalentPool } from './TalentPool/TalentPool'
import { ProgramDetails } from './TalentPool/Programs/ProgramDetails'
import { NotFound } from '../Common/ErrorPages'
import Chat from '../ManagerBackOffice/HelpDesk/Chat/Chat'

export const UserBackOfficeRoutes = () => {
  return (
    <Routes>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="properties" element={<Properties />} />
      <Route path="talent-pool" element={<TalentPool />} />
      <Route path="talent-pool/:id" element={<ProgramDetails />} />
      <Route path="properties/:id" element={<PropertiesDetail />} />


      <Route path="chat" element={<Chat />} />
      <Route path="*" element={<NotFound/>} />
    </Routes>
  )
}
