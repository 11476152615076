import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form';

import { BtnBold, BtnBulletList, BtnClearFormatting, BtnItalic, BtnLink, BtnNumberedList, BtnUnderline, Editor, EditorProvider, Separator, Toolbar } from 'react-simple-wysiwyg';

const TextEditor = ({ control, name, ...props }: any) => {



    return (<>
        <EditorProvider>
            <Controller
                render={({ field }: any) => <>

                    <Editor
                        style={{minHeight:200}}
                        {...field}
                        
                    >
                        <Toolbar>
                            <BtnBold />
                            <BtnItalic />
                            <BtnUnderline />
                            <Separator />
                            <BtnBulletList />
                            <BtnNumberedList />
                            <Separator />
                            <BtnLink />
                            <BtnClearFormatting />

                        </Toolbar>
                    </Editor></>
                }
                name={name}
                control={control}
                defaultValue={props.value}
            />
        </EditorProvider>
    </>);
}

export { TextEditor };