import React, { useEffect, useRef, useState } from 'react'
import ChatBumble from './ChatBumble'
import { socket } from '../../../../utils/socket';
import { apiRequest } from '../../../../utils/api';
import { ProgressLoading } from '../../../Common/components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


interface Message {
	id: number;
	chatId: number;
	user: string;
	content: string;
	createdAt: string;
}
const ChatContent = ({ chat, user, full, member }: any) => {
	const [messages, setMessages] = useState<Message[]>([]);
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState<boolean>(false);

	const chatContainerRef = useRef<HTMLDivElement | null>(null);
	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	}, [messages]);

	useEffect(() => {

		if (chat?.id) {
			setLoading(true)
			apiRequest({
				endpoint: '/common/messages',
				method: 'get',
				urlQueryParams: { page: 1, limit: 100, chatId: chat?.id }
			}).then(({ data }: any) => {
				setMessages(data)
			})
				.finally(() => setLoading(false))
		}
	}, [chat, user])



	const sendMessage = () => {
		if (message.length > 0) {
			setLoading(true);
			socket.emit('sendMessage', { chatId: chat?.id, memberId: member?.id, content: message });
			setMessage('');
		}
	};

	useEffect(() => {

		const chatId = chat?.id;

		socket.emit('joinChat', chatId);

		socket.on('receiveMessage', (newMessage: any) => {
			setMessages((prev) => [...prev, newMessage]);
			setLoading(false)
		});

		return () => {
			socket.emit('leaveChat', chatId);
			socket.off('receiveMessage');
		};
	}, [chat, user]);


	return (
		<div className="content" style={full ? { width: '100%' } : {}}>
			{loading && <ProgressLoading />}
			{chat === undefined || user === undefined ? <></> : <>

				<div className="messages" ref={chatContainerRef} >
					<ul>
						{messages.map((message: any) => (
							<ChatBumble key={message.id} type={user?.id !== message.member?.userId ? "sent" : "replies"} message={message} />
						))}
					</ul>
				</div>
				<div className="message-input">
					{loading && <ProgressLoading />}
					{member?.id &&
						<div className="wrap">
							<input type="text" placeholder="Write your message..." value={message}
								onKeyDown={(e) => {
									if (e.key === "Enter")
										sendMessage();
								}}
								onChange={(e) => setMessage(e.target.value)} />


							<button className="button" onClick={sendMessage}>
								<i className="fa fa-paper-plane" aria-hidden="true"></i>
							</button>
						</div>
					}
				</div>
			</>
			}
		</div>
	)
}

export default ChatContent
