import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { RegulationText } from './RegulationText'

export const RegulationModal = ({ show, setShow, onAccept, }: any) => {


    const handleClose = () => setShow(false)
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Regulamento do Laboratório de Talentos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RegulationText />
                <hr />
                <Button variant="secondary" onClick={handleClose}>
                    Rejeito
                </Button>
                <Button variant="success" onClick={() => {
                    onAccept();
                    setShow(false)
                }}>
                    Aceito e continuar
                </Button>
            </Modal.Body>
        </Modal>
    )
}
