import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ProgramDetailsNavBar from './components/ProgramDetailsNavBar'
import { apiRequest } from '../../../../../utils/api'
import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import { ProgramCreate } from '../ProgramCreate/ProgramCreate'
import EventList from './components/Events/EventList'
import ProgramDelete from './components/ProgramDelete'
import MentorList from './components/Mentors/MentorList'
import ParticipantList from './components/Participant/ParticipantList'
import Chat from '../../../HelpDesk/Chat/Chat'

export const ProgramDetails = () => {

    const { id } = useParams()

    const [tab, setTab] = useState<number>(0);
    const [refresh, setRefresh] = useState<number>();
    const [showProgramCreateModal, setShowProgramCreateModal] = useState<boolean>(false)
    const [program, setProgram] = useState<any>({})

    const CurrentTab: any = [EventList, MentorList, ParticipantList, Chat][tab];
    useEffect(() => {
        apiRequest({
            endpoint: '/programs/' + id,
            method: 'get',
        }).then(({ data }: any) => {
            setProgram(data)
        }).catch(err => console.error(err))
    }, [refresh])
    return (
        <div>

            <Row>
                <Col md={12}>
                    <div className="d-md-flex align-items-center mb-3 mx-2">
                        <div className="mb-md-0 mb-3">
                            <h3 className="font-weight-bold mb-0">{program?.title}</h3>
                            <p className="mb-0">De {moment(program?.startEventDate).format("DD/MM/YYYY")} á {moment(program?.endEventDate).format("DD/MM/YYYY")}

                            </p>

                            <p className="mb-0"> Duracao {moment(program?.endEventDate).diff(program?.startEventDate, 'days')} dias - {moment(program?.endEventDate).fromNow()}

                            </p>
                        </div>
                        <button type="button" className="btn btn-sm btn-white btn-icon d-flex align-items-center mb-0 ms-md-auto mb-sm-0 mb-2 me-2">
                            <span className="btn-inner--icon">
                                <span className={"p-1 bg-" + (program?.isActive ? "success" : "danger") + " rounded-circle d-flex ms-auto me-2"}>

                                </span>
                            </span>
                            <span className="btn-inner--text">
                                {program?.isActive
                                    ? "Activo"
                                    : "Desactivado"}
                            </span>
                        </button>

                        <div className="dropdown">
                            <button className="btn btn-sm btn-dark btn-icon d-flex align-items-center mb-0 me-2 dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                Accoes
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>

                                </li>
                                <li>

                                    <span className="dropdown-item" onClick={() => setShowProgramCreateModal(true)}>
                                        Editar
                                    </span>
                                </li>
                                <li>
                                    <ProgramDelete program={program}>

                                        <span className="dropdown-item" onClick={() => setShowProgramCreateModal(false)}>
                                            Eliminar
                                        </span>
                                    </ProgramDelete>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <ProgramCreate program={program} refresh={() => setRefresh(Math.random())} show={showProgramCreateModal} setShow={setShowProgramCreateModal} />
                </Col>
            </Row>
            <Row className='.not-fluid'>

            </Row>
            <>
                <Row>
                    <Col md={3}>
                        <div className="card border shadow-xs mb-4">
                            <div className="card-body text-start p-3 w-100">
                                <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                                    <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                                        <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="text-sm text-secondary mb-1">Tarefas</p>
                                            <h4 className="mb-2 font-weight-bold">17</h4>
                                            <div className="d-flex align-items-center">
                                                <span className="text-sm text-success font-weight-bolder">
                                                    <i className="fa fa-chevron-up text-xs me-1"></i>10.5%
                                                </span>
                                                <span className="text-sm ms-1">from $89,740.00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="card border shadow-xs mb-4">
                            <div className="card-body text-start p-3 w-100">
                                <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                                    <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                                        <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <p className="text-sm text-secondary mb-1">Insrcitos</p>
                                            <h4 className="mb-2 font-weight-bold">17</h4>
                                            <div className="d-flex align-items-center">
                                                <span className="text-sm text-success font-weight-bolder">
                                                    <i className="fa fa-chevron-up text-xs me-1"></i>10.5%
                                                </span>
                                                <span className="text-sm ms-1">from $89,740.00</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="content" dangerouslySetInnerHTML={{__html: program?.description}}></div>
                    </Col>
                    <Col md={8}>
                        <div className="card shadow-xs border">
                            <div className="card-header border-bottom pb-0">

                                <div className="pb-3 d-sm-flex align-items-center">
                                    <div className="btn-group" role="group" aria-label="Basic radio toggle button group">

                                        <input type="radio" className="btn-check" name="btnradiotable" id="btnradiotable0" checked={tab === 0} />
                                        <label onClick={() => setTab(0)} className="btn btn-white px-3 mb-0" htmlFor="btnradiotable0">
                                            <span className="btn-inner--icon">
                                                <i className="fa-solid fa-list-check" style={{ fontSize: 16 }}></i>
                                            </span> {" "}
                                            Tarefas</label>
                                        <input type="radio" className="btn-check" name="btnradiotable" id="btnradiotable1" checked={tab === 1} />
                                        <label onClick={() => setTab(1)} className="btn btn-white px-3 mb-0" htmlFor="btnradiotable1">
                                            <span className="btn-inner--icon">
                                                <i className="fa-solid fa-user-tie" style={{ fontSize: 16 }}></i>
                                            </span> {" "}
                                            Mentores</label>
                                        <input type="radio" className="btn-check" name="btnradiotable" id="btnradiotable2" checked={tab === 2} />
                                        <label onClick={() => setTab(2)} className="btn btn-white px-3 mb-0" htmlFor="btnradiotable2">
                                            <span className="btn-inner--icon">
                                                <i className="fa-solid fa-users" style={{ fontSize: 16 }}></i>
                                            </span> {" "}
                                            Inscritos</label>
                                        <input type="radio" className="btn-check" name="btnradiotable" id="btnradiotable3" checked={tab === 3} />
                                        <label onClick={() => setTab(3)} className="btn btn-white px-3 mb-0" htmlFor="btnradiotable3">
                                            <span className="btn-inner--icon">
                                                <i className="fa-brands fa-rocketchat" style={{ fontSize: 16 }}></i>
                                            </span>

                                            {" "} Chat</label>
                                    </div>
                                    <div className="input-group w-sm-25 ms-auto">
                                        <span className="input-group-text text-body">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
                                            </svg>
                                        </span>
                                        <input type="text" className="form-control" placeholder="Search" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body px-0 py-0">
                                <CurrentTab program={program} currentChat={program?.chat} full={true} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </>

        </div >
    )
}
