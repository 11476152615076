import React, { useEffect, useState } from 'react'
import { apiRequest } from '../../../../../../../utils/api';
import MentorCreate from './MentorCreate';
import moment from 'moment';
import { env } from 'process';
import MentorDelete from './MentorDelete';
import { ProgressLoading } from '../../../../../../Common/components/Loading';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MentorList = ({ program }: any) => {


  const [{ data: mentors, ...data }, setMentors] = useState<any>({ data: [] })
  const [selectedMentor, setMentor] = useState<any>({})

  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState<number>();
  const [showProgramCreateModal, setShowProgramCreateModal] = useState(false);
  useEffect(() => {
    if (program?.id) {
      setLoading(true)
      apiRequest({
        endpoint: '/Program-mentors',
        method: 'get',
        urlQueryParams: { page: currentPage, limit: 6, programId: program?.id }
      }).then((data) => {
        setMentors(data)
      }).catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }, [program, currentPage, refresh])

  return (
    <>
      <div className="row  mb-6">

        {mentors?.map((mentor: any) =>
          <>
            <div className="col-md-4 col-sm-6">
              <div className="card blur border border-white mb-4 shadow-xs">
                <div className="card-body p-4">
                <img src="/assets/img/unknow.png" className="w-80" alt="spotify" />
                  <p className="text-sm mb-1">Mentor</p>
                  <h6 className="mb-0 font-weight-bold">
                    {mentor?.user?.userData?.firstName} {" "}
                    {mentor?.user?.userData?.lastName}
                  </h6>
                  <Button variant='light' className='m-1'><i className="fa-brands fa-linkedin"></i></Button>
                  <Button variant='light' className='m-1'><i className="fa-brands fa-instagram"></i></Button>
                  
                </div>
              </div>
            </div>
          </>)}
        {loading && <ProgressLoading />}
      </div>
    </ >
  )
}

export default MentorList
