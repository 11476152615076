import React, { useContext, useEffect, useState } from 'react'
import { apiRequest } from '../../../../../../../utils/api';
import EventCreate from './EventCreate';
import moment from 'moment';
import { env } from 'process';
import EventDelete from './EventDelete';
import { ProgressLoading } from '../../../../../../Common/components/Loading';
import { loadavg } from 'os';
import { Accordion, AccordionContext, Button, ButtonGroup, ListGroup } from 'react-bootstrap';

const EventList = ({ program }: any) => {


  const [{ data: events, ...data }, setEvents] = useState<any>({})
  const [selectedEvent, setEvent] = useState<any>({})

  const [currentPage, setCurrentPage] = useState(1);
  const [currecntEvent, setCurrentEvent] = useState<any>();
  const [refresh, setRefresh] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (program?.id) {
      setLoading(true)
      apiRequest({
        endpoint: '/events',
        method: 'get',
        urlQueryParams: { page: currentPage, limit: 6, programId: program?.id }
      }).then((data) => {
        setEvents(data)
      }).catch(err => console.error(err))
        .finally(() =>
          setLoading(false))
    }
  }, [program, currentPage, refresh])


  return (
    <>

      <Accordion defaultActiveKey="0">
        {events?.map((event: any, index: number) => <>
          <AccordionItem index={index} event={event} setCurrent={setCurrentEvent} />
        </>
        )}
      </Accordion>
      {loading && <ProgressLoading />}
    </>
  )
}

const AccordionItem = ({ event, index, setCurrent }: any) => {
  return (<>
    <Accordion.Item eventKey={`${index}`}>
      <Accordion.Header onClick={() => setCurrent(event)}>{index + 1}. {event?.title}</Accordion.Header>
      <Accordion.Body>

        <ButtonGroup aria-label="Basic example">
          <Button variant="light">
            Data de Inicio<br/>
            
            <b>{moment(event?.startEventDate).format('DD/MM/YYYY')}</b>
          </Button>
          <Button variant="light">
          Data de fim<br/>
          <b>{moment(event?.endEventDate).format('DD/MM/YYYY')}</b>
          </Button>
        </ButtonGroup>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: event?.description }} />
      </Accordion.Body>
    </Accordion.Item>
  </>)
}
export default EventList
