import React, { useEffect, useState } from 'react'

import moment from 'moment'
import { Link, useNavigate } from 'react-router-dom'
import { apiRequest } from '../../../utils/api'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { ProgressLoading } from '../../Common/components/Loading'

export const TalentPool = () => {
    const navigate = useNavigate()

    const [{ data: programs, ...data }, setPrograms] = useState<any>({})
    const [{ data: Myprograms, ...myData }, setMyPrograms] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true);

    const [currentPage, setCurrentPage] = useState(1);

    const [refresh, setRefresh] = useState<number>();
    const [showUserCreateModal, setShowUserCreateModal] = useState(false);
    useEffect(() => {
        setLoading(true)
        apiRequest({
            endpoint: '/programs',
            method: 'get',
            urlQueryParams: { page: currentPage, limit: 8 }
        }).then((data) => {
            setPrograms(data)
        }).catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [currentPage, refresh])

    return (
        <>
            <MyPrograms />
            <div className="row">
                {loading && <ProgressLoading />}
                {programs?.map((program: any) =>
                    <Link to={"/my/talent-pool/" + program?.id} className="col-xl-3 col-sm-6 mb-xl-0" >
                        <div className="card border shadow-xs mb-4">
                            <div className="card-body text-start p-3 w-100">
                                <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                                    <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                                        <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clip-rule="evenodd"></path>
                                    </svg>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="w-100">
                                            <h4 className="mb-2 font-weight-bold">{program?.title}</h4>
                                            <p className="text-sm text-secondary mb-1">{program?.title}</p>
                                            <div className="d-flex align-items-center">


                                                <span className="text-sm ms-1">{/*moment(program?.startEventDate).format('DD/MM/YYYY')*/}</span>
                                            </div>

                                        </div>
                                        <Row>
                                            <Col>
                                                {program?.isActive
                                                    ? <span className="badge badge-sm border border-success text-success bg-success">Activo</span>
                                                    : <span className="badge badge-sm border border-danger text-danger bg-danger">Desactivado</span>}</Col>
                                            <Col className="text-sm ms-1 text-end">
                                                <span className="text-sm ms-1 text-end" style={{ float: 'right' }}>
                                                    {moment(program?.createdAt).fromNow()}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </Link>)}

                <div className="border-top py-3 px-3 d-flex align-items-center">
                    <p className="font-weight-semibold mb-0 text-dark text-sm">Pagina {data?.page} de {data?.totalPages} - {data?.totalCount} registos</p>
                    <div className="ms-auto">
                        <button className="btn btn-sm btn-white mb-0" disabled={data?.page === 1} onClick={() => data?.page > 1 && setCurrentPage(data?.page - 1)}>Anterior</button>
                        <button className="btn btn-sm btn-white mb-0" disabled={data?.page === data?.totalPages} onClick={() => data?.page < data?.totalPages && setCurrentPage(data?.page + 1)}>Proximo</button>
                    </div>
                </div>
            </div>
        </>
    )
}


const MyPrograms = () => {

    const { user } = useSelector((state: RootState) => state.auth)
    const navigate = useNavigate()
    const [{ data: myPrograms, ...myData }, setMyPrograms] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true);

    const [currentPage, setCurrentPage] = useState(1);

    const [refresh, setRefresh] = useState<number>();

    useEffect(() => {
        if (user?.id) {
            setLoading(true)
            apiRequest({
                endpoint: '/programs',
                method: 'get',
                urlQueryParams: { page: currentPage, limit: 4, userId: user?.id }
            }).then((data) => {
                setMyPrograms(data)
            }).catch(err => console.error(err))
                .finally(() => setLoading(false))
        }
    }, [currentPage, refresh, user])

    return (<>
        <div className='row'>
            <div className="col-12">
                <div className="card card-background card-background-after-none align-items-start mt-4 mb-5">
                    <div className="full-background" style={{ backgroundImage: "url('../assets/img/header-blue-purple.jpg')" }}></div>
                    <div className="card-body text-start p-4 w-100">
                        <h3 className="text-white mb-2">Meus Programas 🔥</h3>
                        <p className="mb-4 font-weight-semibold">
                            ...
                        </p>
                        <Row>
                            {loading && <ProgressLoading />}
                            {myPrograms?.map((program: any) =>
                                <Link to={"/my/talent-pool/" + program?.id} className="col-xl-3 col-sm-6 mb-xl-0" style={{ color: "#333" }}>
                                    <div className="card border shadow-xs mb-4">
                                        <div className="card-body text-start p-3 w-100">
                                            <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                                                <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                                    <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                                                    <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clip-rule="evenodd"></path>
                                                </svg>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="w-100">
                                                        <h4 className="mb-2 font-weight-bold" style={{ color: '#555' }}>{program?.title}</h4>
                                                        <p className="text-sm text-secondary mb-1">{program?.title}</p>
                                                        <div className="d-flex align-items-center">


                                                            <span className="text-sm ms-1">{moment(program?.startEventDate).format('DD/MM/YYYY')}</span>
                                                        </div>

                                                    </div>
                                                    <Row>
                                                        <Col>
                                                            {program?.isActive
                                                                ? <span className="badge badge-sm border border-success text-success bg-success">Activo</span>
                                                                : <span className="badge badge-sm border border-danger text-danger bg-danger">Desactivado</span>}</Col>
                                                        <Col className="text-sm ms-1 text-end">
                                                            <span className="text-sm ms-1 text-end" style={{ float: 'right', color: "#333" }}>
                                                                {moment(program?.startEventDate).fromNow()}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </Link>)}
                        </Row>
                        <button type="button" className="btn btn-outline-white btn-blur btn-icon d-flex align-items-center mb-0">
                            <span className="btn-inner--icon">

                            </span>
                            <span className="btn-inner--text">Ver mais</span>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    </>)
}