import React from 'react'

export const RegulationText = () => {
  return (

      <div style={{margin:'2em'}}>


        <h4>Cláusula 1ª</h4>
        <b>(Objectivo do Laboratório de Talentos)</b>
        <p>
          O Laboratório de Talentos, doravante “Programa”, visa promover a integração no mercado de trabalho e o desenvolvimento profissional dos jovens, mediante a preparação dos Participantes para os processos de recrutamento e selecção, bem como o desenvolvimento de competências e capacidades requeridas no mercado de trabalho com base nas necessidades actuais e perspectivas de evolução do contexto laboral.
        </p>

        <h4>Cláusula 2ª</h4>
        <b>(Público-alvo)</b>
        <p>
          O Programa destina-se aos estudantes universitários e recém-licenciados, com idade entre 18 a 35 anos, independentemente da área de formação.
        </p>

        <h4>Cláusula 3ª</h4>
        <b>(Duração)</b>
        <ol>
          <li>
            O Programa tem a duração de seis (6) meses, estando constituído por quatro (4) módulos, cada um dos quais envolve a realização de um conjunto de actividades e tarefas.
          </li>
          <li>
            Anualmente são realizadas duas edições, sendo a primeira com início no dia 1 de Março e a segunda com início no dia 1 de Outubro.
          </li>
          <li>
            A participação no Programa requer uma dedicação média de duas (2) horas por semana.
          </li>
        </ol>

        <h4>Cláusula 4ª</h4>
        <b>(Modalidade)</b>
        <ol>
          <li>
            O Programa é desenvolvido predominantemente na modalidade não presencial (online), através da plataforma Mulemba App.
          </li>
          <li>
            Pontualmente, poderão ser realizadas actividades presenciais, sendo a participação limitada em função das vagas disponíveis.
          </li>
        </ol>

        <h4>Cláusula 5ª</h4>
        <b>(Benefícios do Programa)</b>
        <ol>
          <li>Desenvolvimento e/ou consolidação de competências técnicas e comportamentais requeridas no mercado de trabalho, com recurso a fóruns de discussão, formação e workshops.</li>
          <li>Experiência prática com resolução de testes, estudos de caso e exercícios práticos.</li>
          <li>Aconselhamento de Mentores com formação especializada e experiência profissional relevante.</li>
          <li>Networking e troca de experiências com profissionais de vários sectores.</li>
          <li>Certificado de Participação (sujeito ao cumprimento das condições definidas neste Regulamento).</li>
        </ol>

        <h4>Cláusula 6ª</h4>
        <b>(Condições de Adesão)</b>
        <ol>
          <li>Inscrever-se no Programa através da plataforma Mulemba App, ou excepcionalmente mediante o preenchimento do Formulário de Inscrição, até 15 dias antes do início do Programa.</li>
          <li>Efectuar o pagamento da taxa de participação no Programa referente ao primeiro mês.</li>
          <li>Cumprir com as disposições previstas no presente Regulamento.</li>
        </ol>

        <h4>Cláusula 7ª</h4>
        <b>(Exclusão do Programa)</b>
        <ol>
          <li>O participante poderá ser excluído do Programa em caso de:
            <ol type="a">
              <li>Incumprimento dos deveres previstos no artigo 8ª do presente Regulamento;</li>
              <li>Conduta inadequada na relação com os outros participantes, mentores e formadores, após uma (1) advertência verbal ou escrita.</li>
              <li>Quebra de confidencialidade, com possíveis responsabilidades civis e criminais.</li>
            </ol>
          </li>
          <li>Em caso de exclusão, o Participante não terá direito de reembolso da taxa de participação paga.</li>
        </ol>

        <h4>Cláusula 8ª</h4>
        <b>(Deveres do Participante)</b>
        <ol>
          <li>Participar nos eventos que for convocado e realizar todas as actividades programadas.</li>
          <li>Efectuar o pagamento da taxa de participação até ao dia 5 de cada mês.</li>
          <li>Manter uma conduta ética e respeitosa com os demais participantes, formadores, mentores e equipa de gestão do Programa.</li>
          <li>Garantir a confidencialidade de informações compartilhadas durante o Programa.</li>
        </ol>

        <h4>Cláusula 9ª</h4>
        <b>(Condições Financeiras)</b>
        <ol>
          <li>Pagamento da taxa de participação no valor de Kz 10 000, sujeito a desconto de 50% para estudantes de universidades com Protocolo de Cooperação assinado com a Everest.</li>
          <li>Em caso de falta de pagamento até o dia 5 de cada mês, o acesso ao programa será suspenso. A reactivação está sujeita ao pagamento das mensalidades em atraso e uma multa de 25%, não podendo o Participante realizar as actividades vencidas.</li>
          <li>O Participante pode efectuar o pagamento parcial (3 meses) ou total (6 meses) da taxa de participação, com um desconto de 10% e 20%, respectivamente.</li>
        </ol>

        <h4>Cláusula 10ª</h4>
        <b>(Regras de Conduta)</b>
        <ol>
          <li>Evitar comportamentos inaceitáveis, como assédio, uso de linguagem ofensiva ou interrupções nas sessões síncronas.</li>
          <li>Participar activamente das actividades, respeitando as datas e os horários estabelecidos.</li>
          <li>Manter um relacionamento cordial e colaborativo com outros participantes.</li>
        </ol>

        <h4>Cláusula 11ª</h4>
        <b>(Critérios de selecção de Participantes nas actividades com vagas limitadas)</b>
        <p>Nas actividades cuja participação esteja condicionada a disponibilidade de vagas, os participantes serão selecionados com base nos seguintes critérios:</p>
        <ol>
          <li>Ordem de inscrição na actividade em questão;</li>
          <li>Taxa de participação nas actividades do Programa;</li>
          <li>Exclusão dos Participantes com histórico de incumprimento dos deveres previstos no presente Regulamento;</li>
          <li>Exclusão dos Participantes já contemplados nas actividades anteriores.</li>
        </ol>

        <h4>Cláusula 12ª</h4>
        <b>(Certificação)</b>
        <ol>
          <li>Terá direito ao certificado de participação no Programa o participante que cumpra 95% das actividades. O certificado será emitido e disponibilizado no formato digital.</li>
          <li>O certificado poderá ser emitido no formato físico mediante solicitação do participante e pagamento do custo de emissão no valor de Kz 10 000. O levantamento do certificado deve ser efectuado nas instalações da Everest, podendo ser entregue nas instalações do participante, ficando sobre a responsabilidade do mesmo os custos de transporte.</li>
        </ol>

        <h4>Cláusula 13ª</h4>
        <b>(Confidencialidade)</b>
        <p>
          Todas as informações compartilhadas durante o Programa devem ser tratadas com confidencialidade. A violação dessa regra poderá resultar em exclusão do programa, além de possíveis sanções legais.
        </p>

        <h4>Cláusula 14ª</h4>
        <b>(Suporte)</b>
        <ol>
          <li>O Participante tem a sua disposição o suporte especializado dos Mentores do Programa e o suporte técnico do Gestor do Programa.</li>
          <li>
            O suporte especializado deve ser solicitado ao Mentor na plataforma Mulemba App, não sendo permitido qualquer contacto entre o Mentor e o Participante fora da referida plataforma. Este suporte inclui aconselhamento sobre temas relacionados ao programa.
          </li>
          <li>
            O suporte técnico deve ser solicitado ao Gestor do Programa na plataforma Mulemba App, via WhatsApp (+244 935 462 360) ou ainda por correio electrónico (<a href="mailto:apoiocliente@everest40.com">apoiocliente@everest40.com</a>). O suporte técnico inclui:
            <ul>
              <li>Acesso e funcionamento da plataforma Mulemba App;</li>
              <li>Apoio na resolução dos estudos de caso, exercícios e casos práticos;</li>
              <li>Dúvidas sobre os conteúdos;</li>
              <li>Esclarecimentos sobre o funcionamento do Programa;</li>
              <li>Pagamentos;</li>
              <li>Outras situações.</li>
            </ul>
          </li>
        </ol>

        <h4>Cláusula 15ª</h4>
        <b>(Dúvidas e esclarecimentos)</b>
        <p>
          Dúvidas ou esclarecimentos adicionais sobre o Programa devem ser solicitados ao Gestor do Programa na plataforma Mulemba App, via WhatsApp (+244 935 462 360) ou ainda por correio electrónico (<a href="mailto:apoiocliente@everest40.com">apoiocliente@everest40.com</a>).
        </p>
      </div>
  )
}
