import React, { useEffect, useState } from 'react'
import './Chat.scss'
import ChatList from './ChatList'
import ChatContent from './ChatContent'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';


const Chat = ({ currentChat = undefined, full = false }: any) => {
	const auth = useSelector((store: RootState) => store.auth)

	const user = auth?.user;

	const [member, setMember] = useState<any>();
	const [selectedChat, selectChat] = useState<any>();
	useEffect(() => {
		if (selectedChat) {
			setMember(selectedChat?.memberships?.find(({ userId }: any) => userId === user?.id));
		}
	}, [selectedChat])
	useEffect(() => {
		if (currentChat?.id){
			selectChat(currentChat)
		}
	}, [currentChat])

	return (
		<>
			<div id="Chat" className={full ? '' : 'full-container'}>
				
				<div id="frame">
					{currentChat
						? <></>
						: <div id="sidepanel">
							<div id="profile">
								<div className="wrap">
									<img id="profile-img" src="http://emilcarlsson.se/assets/mikeross.png" className="online" alt="" />
									<p>{user?.name || user?.email}</p>
									<i className="fa fa-chevron-down expand-button" aria-hidden="true"></i>
									<div id="status-options">
										<ul>
											<li id="status-online" className="active"><span className="status-circle"></span> <p>Online</p></li>
											<li id="status-away"><span className="status-circle"></span> <p>Away</p></li>
											<li id="status-busy"><span className="status-circle"></span> <p>Busy</p></li>
											<li id="status-offline"><span className="status-circle"></span> <p>Offline</p></li>
										</ul>
									</div>
									<div id="expanded">
										<label htmlFor="twitter"><i className="fa fa-facebook fa-fw" aria-hidden="true"></i></label>
										<input name="twitter" type="text" value="mikeross" />
										<label htmlFor="twitter"><i className="fa fa-twitter fa-fw" aria-hidden="true"></i></label>
										<input name="twitter" type="text" value="ross81" />
										<label htmlFor="twitter"><i className="fa fa-instagram fa-fw" aria-hidden="true"></i></label>
										<input name="twitter" type="text" value="mike.ross" />
									</div>
								</div>
							</div>
							<div id="search">
								<label htmlFor=""><i className="fa fa-search" aria-hidden="true"></i></label>
								<input type="text" placeholder="Search contacts..." />
							</div>
							<ChatList user={user} selectChat={selectChat} selectedChat={selectedChat} />
							<div id="bottom-bar">
								<button id="addcontact"><i className="fa fa-user-plus fa-fw" aria-hidden="true"></i> <span>Add contact</span></button>
								<button id="settings"><i className="fa fa-cog fa-fw" aria-hidden="true"></i> <span>Settings</span></button>
							</div>
						</div>}
					<ChatContent full={full} member={member} chat={selectedChat} user={user} />
				</div>
			</div>
		</>
	)
}

export default Chat
