import React from 'react'
import { Col, Row } from 'react-bootstrap'

export const NotFound = () => {
    return (
        <div >
            <Row style={{ padding: ' 3em 0' }}>

                <Col md={4} style={{ padding: '5em' }}>
                    <img  src="https://img.icons8.com/cotton/300/page-not-found.png" alt="page-not-found" style={{width:'100%'}} />
                </Col>
                <Col md={8}>
                    <h1>Oops! Página não encontrada 😢</h1>
                    <p>Parece que você tentou acessar algo que não está aqui. Isso pode ter acontecido por:</p>
                    <ul>
                        <li>O endereço foi digitado incorretamente.</li>
                        <li>O link que você seguiu está desatualizado.</li>
                        <li>A página foi removida ou movida.</li>
                    </ul>
                    <p><strong>O que você pode fazer?</strong></p>
                    <ul>
                        <li><a href="/">Voltar para a Página Inicial</a></li>
                        <li>Usar o menu de navegação para encontrar o que procura.</li>
                        <li><a href="/contato">Entre em contato conosco</a> se precisar de ajuda.</li>
                    </ul>
                    <p><strong>Estamos aqui para ajudar!</strong></p>
                </Col>
            </Row>

        </div>
    )
}
