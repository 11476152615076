import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ProgramDetailsNavBar from './components/ProgramDetailsNavBar'
import { apiRequest } from '../../../../../utils/api'
import { Card, CardBody, Col, Row } from 'react-bootstrap'
import moment from 'moment'
import { ProgramCreate } from '../ProgramCreate/ProgramCreate'
import EventList from './components/Events/EventList'
import ProgramDelete from './components/ProgramDelete'
import MentorList from './components/Mentors/MentorList'
import ParticipantList from './components/Participant/ParticipantList'
import { ProgressLoading, PulseLoading } from '../../../../Common/components/Loading'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../app/store'
import Chat from '../../../../ManagerBackOffice/HelpDesk/Chat/Chat'
import { RegulationModal } from './components/Regulation/RegulationModal'
import { ProgramEnrolling } from './components/ProgramEnrolling/ProgramEnrolling'

export const ProgramDetails = () => {

    const { id } = useParams()
    const auth = useSelector((state: RootState) => state.auth)

    const [refresh, setRefresh] = useState<number>();
    const [showProgramCreateModal, setShowProgramCreateModal] = useState<boolean>(false)
    const [program, setProgram] = useState<any>({})
    const [enrolled, setEnrolled] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true)
        apiRequest({
            endpoint: '/programs/' + id,
            method: 'get',
        }).then(({ data }: any) => {
            setProgram(data)
        }).catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [refresh])
    return (
        <Row>
            <div className='row'>
                <div className="col-12">
                    <div className="card card-background card-background-after-none align-items-start mt-4 mb-5">
                        <div className="full-background" style={{ backgroundImage: "url('/assets/img/header-blue-purple.jpg')" }}></div>
                        <div className="card-body text-start p-4 w-100">
                            <h1 className="text-white mb-2">{program?.title}</h1>

                            <p className="mb-0">
                                De {moment(program?.startEventDate).format("DD/MM/YYYY")}
                                á {moment(program?.endEventDate).format("DD/MM/YYYY")}
                            </p>

                            <p className="mb-0"> Duracao {moment(program?.endEventDate).diff(program?.startEventDate, 'days')} dias - {moment(program?.endEventDate).fromNow()}

                            </p>
                            <Row>
                                {loading && <ProgressLoading />}

                            </Row>
                            <button type="button" className="btn btn-outline-white btn-blur btn-icon d-flex align-items-center mb-0">
                                <span className="btn-inner--icon">

                                </span>
                                {/*<span className="btn-inner--text">Ver mais</span>*/} . . .
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <Row>
                <Col md={12}>
                    <div className="d-md-flex align-items-center mb-3 mx-2">
                        <div className="mb-md-0 mb-3">
                            <h3 className="font-weight-bold mb-0">{program?.title}</h3>

                        </div>
                        <button type="button" className="btn btn-sm btn-white btn-icon d-flex align-items-center mb-0 ms-md-auto mb-sm-0 mb-2 me-2">
                            <span className="btn-inner--icon">
                                <span className={"p-1 bg-" + (program?.isActive ? "success" : "danger") + " rounded-circle d-flex ms-auto me-2"}>

                                </span>
                            </span>
                            <span className="btn-inner--text">
                                {program?.isActive
                                    ? "Activo"
                                    : "Desactivado"}
                            </span>
                        </button>


                    </div>
                    <ProgramCreate program={program} refresh={() => setRefresh(Math.random())} show={showProgramCreateModal} setShow={setShowProgramCreateModal} />
                </Col>
            </Row>
            <Col md={8}>


                <Row>

                </Row>
                <Row className='mb-6'>
                    <Col >
                        {program?.description}
                    </Col>

                </Row>
                <Row className='mb-6'>
                    <h3>Tarefas</h3>
                    <Col md={12}>
                        <EventList program={program} />
                    </Col>
                </Row>
                <hr className="horizontal mb-4 dark" />

                <Row>

                    <h3>Mentores</h3>
                    <MentorList program={program} />

                </Row>
            </Col>

            <Col md={4}>
                <ProgramParticipantRegister
                    program={program}
                    user={auth?.user} refresh={() => setRefresh(Math.random())}
                    enrolled={enrolled} setEnrolled={setEnrolled} />
                <ProgramChat program={program} enrolled={enrolled} />
            </Col>

        </Row >
    )
}


const ProgramParticipantRegister = ({ program, refresh: outerRefresh, user, enrolled, setEnrolled }: any) => {
    const [refresh, setRefresh] = useState<any>();
    const [loading, setLoading] = useState<any>();
    const [{ data: enrolments = [], totalCount }, setEnrolments] = useState<any>({ data: [] });



    const enroll = () => {
        setLoading(true)
        apiRequest({
            endpoint: '/program-participants' + (enrolled?.id ? `/${enrolled?.id}` : ''),
            method: enrolled?.id ? 'delete' : 'post',
            body: { programId: program?.id, userId: user?.id }
        }).then(({ data }) => {
            if (data?.id) {
                Swal.fire({
                    title: "Obrigado pelo seu interesse!",
                    html: `A sua pré-inscrição no Programa <b>${program?.title}</b> foi efectuada com sucesso. <br/>Após o pagamento, o Programa será activo automaticamente. <br/>Para informação ou esclarecimentos adicionais, queira contactar-nos (WhatsApp 935 462 360).<br/>Everest - Juntos ao Topo!`,
                    icon: "success"
                });

            } else {
                Swal.fire({
                    title: "Obrigado pelo seu interesse.!",
                    text: "Removeu a sua Inscrição ao Programa program?.title  com sucesso!",
                    icon: "success"
                });
            }
            setRefresh(Math.random())
            outerRefresh();
        }).catch(({ data }) => console.log(data))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (program?.id) {
            setLoading(true)
            apiRequest({
                endpoint: '/program-participants',
                method: 'get',
                urlQueryParams: { programId: program?.id, limit: 100 }
            }).then((data) => {
                setEnrolments(data);
                setEnrolled(data?.data?.find(({ userId }: any) => userId === user?.id))
            }).catch(({ data }) => console.log(data))
                .finally(() => setLoading(false))
        }
    }, [refresh, program])

    return (<>
        <div className="card border shadow-xs mb-4">
            <div className="card-body text-start p-3 w-100">
                <div className="icon icon-shape icon-sm bg-dark text-white text-center border-radius-sm d-flex align-items-center justify-content-center mb-3">
                    <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                        <path d="M4.5 3.75a3 3 0 00-3 3v.75h21v-.75a3 3 0 00-3-3h-15z"></path>
                        <path fill-rule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 003 3h15a3 3 0 003-3v-7.5zm-18 3.75a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z" clip-rule="evenodd"></path>
                    </svg>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="w-100">
                            <p className="text-sm text-secondary mb-1">Insrcitos</p>
                            <h4 className="mb-2 font-weight-bold"> {loading && <PulseLoading />} {" "} {totalCount}</h4>
                            <div className="d-flex align-items-center">

                                <ProgramEnrolling enrolled={enrolled} enroll={enroll} loading={loading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

const ProgramChat = ({ enrolled, program }: any) => {
    const [loading, setLoading] = useState<any>();
    const [chat, setChat] = useState<any>();

    useEffect(() => {
        if (program?.chatId) {
            setLoading(true)
            apiRequest({
                endpoint: '/common/chats/' + program?.chatId,
                method: 'get',
                urlQueryParams: { programId: program?.id, limit: 100 }
            }).then(({ data }) => {
                setChat(data);
            }).catch(({ data }) => console.log(data))
                .finally(() => setLoading(false))
        }
    }, [program])

    return (<>

        {enrolled?.id && chat?.id &&
            <Card>
                <Card.Title >
                    <h3 className=' p-3 '>Chat</h3>
                </Card.Title>
                <Card.Body style={{ padding: 0 }}>

                    {loading && <ProgressLoading />}
                    <Chat currentChat={chat} full={true} />

                </Card.Body>
            </Card>}
    </>)
}